export const HomeSpanish =
{
    h2: 'Construyendo puentes a través \n de la educación y la traducción',
    button: 'Contactame!'
}

export const HomeEnglish =
{
    h2: 'Building bridges through \n education and translation',
    button: 'Contact'
}