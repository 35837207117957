import './BurgerMenu.css'

const BurgerMenu  = () => {

    

    return(
        <>
        <a href="#" class="burger">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
        </a>
        </>
    )
}

export default BurgerMenu;