
import './CoursesInfo.css';


const CoursesText = ({text}) => {

    return (
        <>
            <p className="courses-text">{text}</p>
        </>
    );
}

export default CoursesText; 